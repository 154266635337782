html,
body {
  padding: 0;
  margin: 0 auto 0 auto;
  font-family: "Montserrat", sans-serif;
  color: rgb(256, 256, 256);
  background-color: rgb(37, 37, 38);
}

p {
  margin: 0;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #2ba1e5;
}

h1 {
  font-size: 32px;
}

h4 {
  margin: 10px 0 10px 0;
}

h6 {
  font-size: 16px;
  font-weight: 100;
  margin: 10px 0 0 0;
}

strong {
  margin: 20px 0 20px 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto 100px auto;
  padding: 10px 40px;
}

.socials {
  font-size: 20px;
  text-align: right;
}

.socials > * {
  margin-left: 20px;
}

@media screen and (max-width: 700px) {
  .socials {
    font-size: 32px;
  }
}

.headshot {
  display: block;
  object-fit: cover;
  border-radius: 50%;
  height: 400px;
  width: 400px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 500px) {
  .headshot {
    width: 80vw;
    height: 80vw;
  }
}

.quote {
  margin: 20px;
  text-align: center;
}

.get-quote {
  margin: 20px;
  text-align: center;
  cursor: default;
}

.greeting {
  text-align: center;
}

.body {
  width: 100%;
}

.section-wrapper {
  border-radius: 10px;

  overflow: hidden;
  margin: 20px 0 0 0;

  color: rgb(0, 0, 0);
}

.header {
  width: 100%;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(238, 232, 228, 0.4);
  background-color: rgb(60, 60, 60);
  background-blend-mode: overlay;

  color: rgb(256, 256, 256);
}
.header.internship {
  padding: 20px 20px 60px 20px;
  border: 15px solid rgb(60, 60, 60);

  background-size: contain;
}
.header.project {
  padding: 35px 35px 75px 35px;

  background-size: cover;
}

.header-body {
  width: 100%;
  background-color: rgb(238, 232, 228);

  padding: 20px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.list {
  display: inline;
}

.list.item {
  padding: 0.1em;
  border-radius: 4px;

  background-color: burlywood;
}

.information {
  margin: 0;
}

/* animations */

.wave {
  animation-name: wave-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(14deg);
  }
  10% {
    transform: rotate(-8deg);
  }
  15% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-4deg);
  }
  25% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
